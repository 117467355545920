/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {Redirect, Route, Switch} from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import NucleoIcons from "views/NucleoIcons.js";
import ContactUs from "views/pages/ContactUs.js";
import ProductsListPage from "views/pages/ProductsListPage.js";
import Error404 from "views/pages/Error404.js";
import Error500 from "views/pages/Error500.js";
import LandingPage from "views/pages/LandingPage.js";
import ProductDetailPage from "views/pages/ProductDetailPage.js";
// others
import './i18n';
import {I18nextProvider} from "react-i18next";
import {combineReducers, createStore} from "redux";
import {connect, Provider} from "react-redux";
import {ConnectedRouter, routerReducer} from "react-router-redux";
import {createBrowserHistory} from "history";

import i18n from "./i18n";

import {reducer, changeLanguage } from "./reducers/reducer";

const lang = i18n.language;

const store = createStore(
    combineReducers({
        app: reducer,
        routing: routerReducer
    })
);

const history = createBrowserHistory();

let App = ({match, location}) => {
    console.log("location", location);
    console.log("match", match);

    if (lang != match.params.locale) {
        changeLanguage(match.params.locale);
    }
    return (

        <Switch>
            <Route
                path="/nucleo-icons"
                render={(props) => <NucleoIcons {...props} />}
            />
            <Route path={`${match.url}/contact-us`} render={(props) => <ContactUs {...props} />}/>
            <Route path={`${match.url}/products`} render={(props) => <ProductsListPage {...props} />}/>
            <Route path={`${match.url}/error-404`} render={(props) => <Error404 {...props} />}/>
            <Route path={`${match.url}/error-500`} render={(props) => <Error500 {...props} />}/>
            <Route
                path={`${match.url}/product-detail/:id`}
                render={(props) => <ProductDetailPage {...props} />}
            />
            <Route
                path={`${match.url}/`}
                render={(props) => <LandingPage {...props} />}
            />
            <Redirect to={`${match.url}/`}/>
        </Switch>

    );
};

App = connect(
    state => ({
        location: state.routing.location
    }),
    null
)(App);

const BasicExample = ({store, history}) => (
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense fallback="loading">
                    <Switch>
                        <Route path="/:locale" component={App}/>
                        <Redirect to="/en"/>
                    </Switch>
                </Suspense>
            </ConnectedRouter>
        </Provider>
    </I18nextProvider>
);

ReactDOM.render(<BasicExample store={store} history={history}/>,
    document.getElementById("root"));

