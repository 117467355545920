import React from "react";
import {Link} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  Container,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";
import {useTranslation} from "react-i18next";
import {changeLanguage, generateLanguage} from "../../reducers/reducer";

// core components

function ColorNavbar() {
    const {t, i18n} = useTranslation();
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [bodyClick, setBodyClick] = React.useState(false);
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
        setNavbarColor("bg-info");
    });
    return (
        <>
            {bodyClick ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setBodyClick(false);
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                className={classnames("fixed-top", navbarColor)}
                expand="lg"
                id="navbar-main"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand id="navbar-brand" to={`/${i18n.language}`} tag={Link}>
                            <img
                                alt="WhiteMountain"
                                src={require("assets/img/white_mountain_black.png")}
                                class="navbar-brand-img"
                            />
                        </NavbarBrand>
                        <button
                            className="navbar-toggler"
                            id="navigation"
                            type="button"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setBodyClick(true);
                                setCollapseOpen(true);
                            }}
                        >
                            <span className="navbar-toggler-bar bar1"/>
                            <span className="navbar-toggler-bar bar2"/>
                            <span className="navbar-toggler-bar bar3"/>
                        </button>
                    </div>
                    <Collapse navbar isOpen={collapseOpen}>
                      <Nav className="ml-auto" navbar>
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle color="default" nav to={`/${i18n.language}/products`} tag={Link}>
                            {t('menu.products')}
                          </DropdownToggle>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle color="default" nav to={`/${i18n.language}/contact-us`} tag={Link}>
                            {t('menu.contact-us')}
                          </DropdownToggle>
                        </UncontrolledDropdown>
                        <NavItem>
                          <Button
                              color="default"
                              href={generateLanguage("fr", window.location)}
                              onClick={() => changeLanguage("fr")}
                          >
                            FR
                          </Button>
                          <Button
                              color="default"
                              href={generateLanguage("en", window.location)}
                              onClick={() => changeLanguage("en")}
                          >
                            EN
                          </Button>
                        </NavItem>
                      </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default ColorNavbar;
