import React, {useState} from "react";
// react plugin used to create DropdownMenu for selecting items
// reactstrap components
import {Card, Carousel, CarouselCaption, CarouselIndicators, CarouselItem, Col, Container, Row,} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ProductPageHeader from "components/Headers/ProductPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

// firebase import
import {db, storage} from "../../base";
import {useTranslation} from "react-i18next";

// carousel items
/*const carouselItems = [
  {
    src: require("assets/img/jacket-1.jpg"),
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("assets/img/jacket-2.jpg"),
    altText: "Somewhere else",
    caption: "Somewhere else",
  },
  {
    src: require("assets/img/jacket-3.jpg"),
    altText: "Here it is",
    caption: "Here it is",
  },
  {
    src: require("assets/img/jacket-4.jpg"),
    altText: "Here it is",
    caption: "Here it is",
  },
];*/

function ProductDetailPage(props) {
    const {t, i18n} = useTranslation();
    const id = props.match.params.id
    // carousel states and functions
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [product, setProduct] = useState({
            title: "",
            description: "",
            price: ""
    });
    const [carouselItems, setCarouselItems] = useState([]);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    //load pictures
    const fetchImages = async () => {

        let result = await storage.child("/" + id).listAll();
        /// map() array of the imageRef.getDownloadURL() promises
        let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());

        // return all resolved promises
        return Promise.all(urlPromises);
    }

    const fetchProduct = async () => {
        console.log("id: ", id)
        const docRef = db.collection("products").doc(id);
        docRef.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                setProduct(doc.data())
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                props.history.push("/" + i18n.language + "/error-404");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
            props.history.push("/" + i18n.language + "/error-505");
        });
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        fetchProduct();
        document.body.classList.add("product-page");
        return function cleanup() {
            document.body.classList.remove("product-page");
        };
    }, [id]);

    React.useEffect(() => {
        fetchImages().then((urls) => {
                console.log("urls:", urls);
                setCarouselItems(urls)
        }).catch((error) => {
            console.log("Error getting images:", error);
        });
    }, [setProduct]);

    return (
        <>
            <ColorNavbar/>
            <ProductPageHeader/>
            <div className="main">
                <div className="section">
                    <Container>
                        <Row>
                            <Col md="7" sm="6">
                                <div className="ml-auto mr-auto" id="carousel">
                                    <Card className="page-carousel">
                                        <Carousel
                                            activeIndex={activeIndex}
                                            next={next}
                                            previous={previous}
                                        >
                                            <CarouselIndicators
                                                items={carouselItems}
                                                activeIndex={activeIndex}
                                                onClickHandler={goToIndex}
                                            />
                                            {carouselItems.map((item) => {
                                                return (
                                                    <CarouselItem
                                                        onExiting={onExiting}
                                                        onExited={onExited}
                                                        key={item}
                                                    >
                                                        <img src={item} alt={product.title}/>
                                                        <CarouselCaption
                                                            captionText={product.title}
                                                            captionHeader=""
                                                        />
                                                    </CarouselItem>
                                                );
                                            })}
                                            <a
                                                className="left carousel-control carousel-control-prev"
                                                data-slide="prev"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    previous();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-left"/>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                            <a
                                                className="right carousel-control carousel-control-next"
                                                data-slide="next"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    next();
                                                }}
                                                role="button"
                                            >
                                                <span className="fa fa-angle-right"/>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </Carousel>
                                    </Card>
                                </div>
                                {/* end carousel */}
                            </Col>
                            <Col md="5" sm="6">
                                <h2>{product.title}</h2>
                                <h4 className="price">
                                    <strong>{product.price}</strong>
                                </h4>
                                <hr/>
                                <p>
                                    {(i18n.language === "en") ? product.descriptionEn : product.descriptionFr}
                                </p>
                                <Row>
                                </Row>
                                <hr/>
                                <Row>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <FooterWhite/>
        </>
    );
}

export default ProductDetailPage;
