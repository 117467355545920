import React from "react";
// reactstrap components
import {Button, Col, Container, Form, Input, Row} from "reactstrap";

// core components
import FooterWhite from "components/Footers/FooterWhite.js";
import ColorNavbar from "../../components/Navbars/ColorNavbar";
import ProductPageHeader from "../../components/Headers/ProductPageHeader";

import {useTranslation} from 'react-i18next';
import Axios from 'axios';

function ContactUs() {
  const {t, i18n} = useTranslation();
  const sendEmailURL = 'https://us-central1-white-mountain-site.cloudfunctions.net/sendEmail';
  const [state, setState] = React.useState({
    message: '',
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
  });

  const {message, firstName, lastName, email, subject} = state;

  const handleState = ({target: {id, value}}) =>
    setState({...state, [id]: value});

  const sendEmail = (e) => {
    e.preventDefault();
    Axios.get(sendEmailURL, {
      params: {
        firstName,
        lastName,
        email,
        subject,
        message
      },
    });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
  return (
    <>
      <ColorNavbar/>
      <ProductPageHeader/>
      <div className="main">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t('contactus_page.title')}</h2>
                <h3 className="title">
                  {t('contactus_page.description')}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h3 className="title">
                  {t('contactus_page_social.title')}
                </h3>
                <Button className="btn-just-icon mr-1" color="facebook">
                  <i className="fa fa-facebook"/>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h3 className="title">
                  <medium>{t('contactus_page_mail.title')}</medium>
                  <br/>
                  <small>{t('contactus_page_mail.mail')}</small>
                  <br/>
                  <small><a href="mailto:info@whitemountaingear.com">info@whitemountaingear.com</a></small>
                  <br/>
                  <br/>
                  <small>{t('contactus_page_mail.form')}</small>
                  <br/>
                </h3>
                <Form className="contact" id="contactForm" onSubmit={sendEmail}>
                  <Row>
                    <Col md="6">
                      <Input placeholder={t('contactus_page_mail.firstName')} type="text" id="firstName" onChange={handleState} value={firstName}/>
                    </Col>
                    <Col md="6">
                      <Input placeholder={t('contactus_page_mail.lastName')} type="text" id="lastName" onChange={handleState} value={lastName}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Input placeholder={t('contactus_page_mail.email')} type="text" id="email" onChange={handleState} value={email}/>
                    </Col>
                    <Col md="6">
                      <Input placeholder={t('contactus_page_mail.subject')} type="text" id="subject" onChange={handleState} value={subject}/>
                    </Col>
                  </Row>
                  <Input placeholder={t('contactus_page_mail.message')} rows="7" type="textarea" id="message" onChange={handleState} value={message}/>
                  <Row>
                    <Col className="ml-auto mr-auto" md="6">
                      <Button block className="btn-round" color="primary" type="submit">
                        {t('contactus_page_mail.submit')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite/>
    </>
  );
}

export default ContactUs;
