import React from "react";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import ProductPageHeader from "../../components/Headers/ProductPageHeader";
import ProductsList from "components/Products/ProductsList"

import {useTranslation} from 'react-i18next';

function ProductsListPage() {
  const {t, i18n} = useTranslation();
  // states for collapses
  const [priceRange, setPriceRange] = React.useState(true);
  const [clothing, setClothing] = React.useState(false);
  const [designer, setDesigner] = React.useState(false);
  const [color, setColor] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("ecommerce-page");
    return function cleanup() {
      document.body.classList.remove("ecommerce-page");
    };
  });

  return (
    <>
      <ColorNavbar />
      <ProductPageHeader />
      <div className="wrapper">
        {/* section */}
        <div className="section section-gray">
          <Container>
            <h3 className="section-title">{t('products_list_page.title')}</h3>
            <Row>
              <Col md="3">
                <h4 className="section-title">{t('products_list_page.description')}</h4>
              </Col>
              <Col md="9">
                <div className="products">
                  <ProductsList/>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* section */}
        <FooterWhite />
      </div>
    </>
  );
}

export default ProductsListPage;
