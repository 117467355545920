import React from "react";

// reactstrap components

// core components

function ProductPageHeader() {

  return (
    <>
      <div
        className="page-header page-header-xs"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/cover.jpg") +
            ")",
        }}
      >
        <div className="filter" />
      </div>
    </>
  );
}

export default ProductPageHeader;
