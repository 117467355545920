import React, {useEffect, useState} from "react";

// reactstrap components
import {Card, CardBody, CardTitle, Col, Row,} from "reactstrap";
// firebase import
import {db, storage} from "../../base";
import {useTranslation} from 'react-i18next';

function ProductsList() {
  const {t, i18n} = useTranslation();
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    db.collection('products').orderBy('title')
      .get()
      .then((querySnapshot) => {
        const productsData = [];
        querySnapshot.forEach((doc) => {
          productsData.push(doc.data());
        });
        console.log(productsData);
        setProducts(productsData);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const fetchImage = async () => {
    const productsData = [];
    for (let product of products) {
     productsData.push({
        ...product,
        imageUrl: await storage.child(product.image).getDownloadURL()
      })
    }
    console.log(productsData);
    setProducts(productsData);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchImage();
  }, [products.length]);

  const listItems =
    products.map((product) => {
        return <Col md="4" sm="4" key={product.title}>
          <Card className="card-product card-plain">
            <div className="card-image">
              <a href={`/${i18n.language}/product-detail/${product.title}`}>
                <img
                  alt={product.title}
                  className="img-rounded img-responsive"
                  src={product.imageUrl}
                />
              </a>
              <CardBody>
                <div className="card-description">
                  <CardTitle tag="h5">{product.title}</CardTitle>
                  <p className="card-description">
                    {(i18n.language === "en") ? product.smallDescriptionEn : product.smallDescriptionFr}
                  </p>
                  <div className="price">
                    <h5>{product.price}</h5>
                  </div>
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>
      }
    );

  return (
    <Row>
      {listItems}
    </Row>
  );
}

export default ProductsList;