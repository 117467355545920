import firebase from "firebase";
// Required for side-effects
import "firebase/firestore";

export const app = firebase.initializeApp({
    "projectId": "white-mountain-site",
    "appId": "1:851561661249:web:447b8d128121e0993057c0",
    "storageBucket": "white-mountain-site.appspot.com",
    "locationId": "northamerica-northeast1",
    "apiKey": "AIzaSyBSQpbEHCEiNAcGx43hgB1g6I1kUPmyfxM",
    "authDomain": "white-mountain-site.firebaseapp.com",
    "messagingSenderId": "851561661249",
    "measurementId": "G-KQM0Q3CSMY"
});

export const db = firebase.firestore();
export const storage = firebase.storage().ref();