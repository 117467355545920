import i18n from "../i18n";
import PathToRegexp, { compile, parse } from "path-to-regexp";

const initialState = {};

export const reducer = (state = initialState, action) => {
    return state;
}

export const generateLanguage = (locale, location) => {
    const ROUTE = "/:locale/:path*";
    const definePath = compile(ROUTE);
    const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);

    let subPaths = null;
    if (routeComponents && routeComponents[2]) {
        subPaths = routeComponents[2].split("/");
    }

    return definePath({
        locale,
        path: subPaths
    });
};

export const changeLanguage = lng => {
    i18n.changeLanguage(lng);
};
